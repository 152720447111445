import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const NavProductStyled = styled.div`
  ul {
    border-bottom: 1px solid var(--white);
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    border-top: 1px solid var(--white);
    padding: 1rem 0;
    text-transform: uppercase;
  }
  ul li ul,
  li:first-of-type {
    border: none;
  }
  li ul li {
    border-top: none;
    text-align: right;
    padding: 0 0 0.5rem;
    a {
      color: var(--watermelon);
    }
  }
  a {
    color: var(--white);
    text-decoration: none;
  }
  p {
    margin: 0 0 1rem;
  }
  @media (max-width: 767px) {
    margin-bottom: -4rem;
  }
`;

export default function NavProduct(props) {
  return (
    <NavProductStyled>
      <h4>Products</h4>
      <ul>
        <li>
          <Link to="/disciplines">Discipline</Link>
          <ul>
            {props.disciplines.nodes.map((discipline) => (
              <li key={discipline.id}>
                <Link to={`/disciplines/${discipline.slug.current}`}>
                  {discipline.title}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <Link to="/series">Series</Link>
          <ul>
            {props.series.nodes.map((item) => (
              <li key={item.id}>
                <Link to={`/series/${item.slug.current}`}>{item.title}</Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <Link to="/products/collection">Collections</Link>
          <ul>
            {props.collections.nodes.map((item) => (
              <li key={item.id}>
                <Link to={`/collections/${item.slug.current}`}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </li>
        <li>
          <ul>
            <li>
              <Link to="/collections/discontinued">Discontinued Models</Link>
            </li>
            <li>
              <Link to="/collections/spare-parts">Spare Parts</Link>
            </li>
          </ul>
        </li>
      </ul>
    </NavProductStyled>
  );
}
