import { createGlobalStyle } from 'styled-components';
import headerFont from '../assets/fonts/AktivGroteskEx_W_XBd.woff';
import headerFont2 from '../assets/fonts/AktivGroteskEx_W_XBd.woff2';
import midFont from '../assets/fonts/pitch-sans-semibold.woff2';
import bodyFont from '../assets/fonts/pitch-sans-regular.woff2';

const Typography = createGlobalStyle`
  @font-face {
    font-family: AktivGrotesk;
    src: url(${headerFont}) format('woff'), url(${headerFont2}) format('woff2');
  }
  @font-face {
    font-family: PitchSansSemi;
    src: url(${midFont});
  }
  @font-face {
    font-family: PitchSansRegular;
    src: url(${bodyFont});
  }
  html {
    font-family: PitchSansRegular, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 150%;
  }
  p {
    font-size: 16px;
    line-height: 150%;
  }
  b,strong {
    font-family: PitchSansSemi;
  }
  h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    font-family: AktivGrotesk, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-transform: uppercase;
    margin: 0;
    line-height: 120%;
  }
  h1 {
      font-size: 96px;
  }
  h2 {
    font-size: 72px;
  }
  h3 {
    font-size: 48px;
  }
  h4,
  .h4 {
    font-size: 32px;
  }
  h5,
  .h5 {
    font-size: 48px;
    font-family: PitchSansSemi;
  }
  h6,
  .h6 {
    text-transform: none;
    font-family: PitchSansSemi;
    font-size: 32px;
    line-height: 130%;
  }
  .h4 {
      display: block;
  }
  a {
    color: var(--textColor);
  }
  .caption {
      font-size: 14px;
  }
  @media (max-width: 1023px) {
    h1 {
        font-size: 60px;
    }
    h2 {
      font-size: 48px;
    }
    h3 {
      font-size: 36px;
    }
    h4,
    .h4{
      font-size: 24px;
    }
    h5 {
      font-size: 32px;
    }
    h6 {
      font-size: 24px;
    }
    a {
      font-size: 16px;
    }
  }

  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default Typography;
