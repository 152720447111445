import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import NavCopyright from './NavCopyright';
import NavProduct from './NavProduct';
import NavSocial from './NavSocial';
import NavInfo from './NavInfo';

const FooterStyled = styled.footer`
  background: var(--blackMatte);
  padding: 6rem 2rem;
  .wrap {
    max-width: var(--wrapper);
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    grid-gap: 5rem;
    @media (max-width: 1023px) {
      grid-gap: 4rem;
    }
    @media (max-width: 727px) {
      grid-template-columns: 1fr;
    }
  }
  h4,
  .h4 {
    color: var(--footerHeading);
    border-top: 1px solid var(--white);
    padding-bottom: 1rem;
    padding-top: 2rem;
    font-size: 28px;
  }
  .h6 {
    color: var(--footerHeading);
    padding-bottom: 0.5rem;
    padding-top: 4rem;
  }
  p,
  input {
    color: var(--white);
  }
`;

export default function Footer() {
  const data = useStaticQuery(graphql`
    query NavQuery {
      technology: allSanityTechnology {
        nodes {
          id
          title
        }
      }
      series: allSanitySeries(
        filter: {
          slug: { current: { nin: ["discontinued", "spare-parts", "multi"] } }
        }
        sort: { fields: order, order: ASC }
      ) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      disciplines: allSanityDisciplines(
        filter: {
          slug: {
            current: { nin: ["discontinued", "spare-parts", "multi", "aero"] }
          }
        }
        sort: { fields: order, order: ASC }
      ) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      collections: allSanityCollections(
        filter: {
          slug: { current: { nin: ["discontinued", "spare-parts", "multi"] } }
        }
      ) {
        nodes {
          id
          title
          slug {
            current
          }
        }
      }
      technology: allSanityTechnology {
        nodes {
          title
          technologyPages {
            title
          }
          id
        }
      }
      support: allSanitySupport {
        nodes {
          title
          id
          supportPages {
            title
            slug {
              current
            }
            href
            inFooter
            inNewTab
          }
        }
      }
    }
  `);
  return (
    <FooterStyled>
      <div className="wrapper wrap">
        <NavSocial />
        <NavProduct
          disciplines={data.disciplines}
          series={data.series}
          collections={data.collections}
        />
        <NavInfo support={data.support} technology={data.technology} />
      </div>
      <NavCopyright />
    </FooterStyled>
  );
}
