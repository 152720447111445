import { createGlobalStyle } from 'styled-components';
import { VscChevronLeft as chevronLeft } from '@react-icons/all-files/vsc/VscChevronLeft';
import { VscChevronRight as chevronRight } from '@react-icons/all-files/vsc/VscChevronRight';
import chevronDown from '../assets/images/chevronDown.svg';

const GlobalStyles = createGlobalStyle`
  :root {
    --greyLight: #f5f5f5;
    --greyMidLight: #d6d6d6;
    --greyMid: #888;
    --greyMidDark: #505050;
    --greyDark: #313131;
    --blackMatte: #222;
    --black: #000;
    --blackTrans: rgba(0,0,0,0.75);
    --white: #fff;
    --whiteTrans: rgba(255,255,255,0.75);
    --bont: #a41925;
    --watermelon: #ff6565;
    --borderColor: #a41925;
    --textColor: #222;
    --backgroundCard: #fff;
    --backgroundProduct: #fff;
    --speed: .3s;
    --border: #222;
    --input: #f5f5f5;
    --footerHeading: #f5f5f5;
    --wrapper: 1440px;
    --boxShadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    --borderRadius: 4px;
  }
  html {
    font-size: 12px;
    box-sizing: border-box;
  }

  *, *:before, *:after {
    box-sizing: inherit;
    touch-action: manipulation;
  }

  body {
    font-size: 16px;
    background: var(--greyLight);
    transition: background 250ms ease-in-out, color 500ms ease-in-out, section 700ms ease-in-out, div 700ms ease-in-out;
    color: var(--textColor);
    overflow-x: hidden;
    .logo img {
      transition: 500ms ease-in-out;
    }
  }

  body.dark {
    --greyLight: var(--blackMatte);
    --border: var(--greyMidLight);
    --borderColor: var(--watermelon);
    --textColor: var(--white);
    --whiteTrans: var(--blackTrans);
    --backgroundProduct: var(--blackMatte);
    --backgroundCard: var(--black);
    .logo img {
      filter: brightness(0) invert(1);
    }
  }

  @keyframes fadein {
    from{opacity:0}
    to{opacity:1}
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  input,
  input.needsclick {
    display: block;
    margin-bottom: 2rem;
    min-height: 40px;
    outline: none;
    border-radius: 0;
    &:focus {
        outline: none;
    }
  }

  input[type="text"],
  input[type="email"]
  input[type="text"].needsclick,
  input[type="email"].needsclick {
        border: 1px solid transparent;
        border-bottom: 1px solid var(--white) !important;
        border-radius: 0 !important;
        background: transparent;
        min-width: 300px;
        max-width: 300px;
      &:focus,
      &:hover {
          border-bottom: 1px solid var(--watermelon)!important;
      }
    }

  button:not(.needsclick),
  select,
  .button {
    background: var(--watermelon);
    color: var(--black);
    border: 1px solid var(--watermelon);
    padding: 0.9rem 1.25rem;
    font-size: 1.5rem;
    height: 44px;
    border-radius: 22px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 4px 4px 4px rgba(250, 250, 250, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.2);
    text-transform: uppercase;
    text-decoration: none;
    margin-right: 2rem;
    transition: all var(--speed);
    &:hover {
      color: var(--white);
      border-color: var(--bont);
      background: var(--bont);
      --cast: 4px;
    }
  }
  button,
  .button {
    text-align: center;
  }
  button.secondary,
  .button.secondary,
  select {
    background: var(--input);
    border-color: var(--blackMatte);
    &:hover {
      color: var(--blackMatte);
      background: var(--white);
    }
  }

  select,
  select:focus,
  select:hover {
    padding-right: 3rem;
    transition: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
      background-image: url(${chevronDown});
      background-position: calc(100% - 1rem) 50%;
      background-size: 2rem;
      background-repeat: no-repeat;
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /*
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--bont) var(--borderColor);
  } */
  body::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background: var(--backgroundProduct);
  }
  body::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    background-color: var(--borderColor) ;
    border-radius: 6px;
    border: 3px solid var(--backgroundProduct);
  }

  img {
    max-width: 100%;
  }

  figure {
    margin: 2rem 0;
    padding: 0;
    img {
      border-radius: var(--borderRadius);
      overflow: hidden;
    }
  }

  blockquote {
    border-left: 1px solid var(--borderColor);
    margin: 2rem 0;
    padding: 2rem;
    text-transform: none;
    font-family: PitchSansSemi;
    font-size: 32px;
    line-height: 130%;
    &::before,
    &::after {
      content: '"';
    }
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .wrapper {
    width: min(100% - 2rem, var(--wrapper));
    margin-inline: auto;
    @media (min-width: 787px) and (max-width: 1440px) {
      width: min(100% - 4rem, var(--wrapper));
    }
  }
  
  .page-header {
    min-height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    display: grid;
    align-items: center;
    justify-items: center;
    border-radius: 2rem;
    color: var(--textColor);
    text-align: center;
    margin: 2rem 0;
    h2 {
      color: var(--textColor);
    }
    @media(max-width:1023px) {
      min-height: 200px;
      font-size: 8vw;
      h2 {
        font-size: 30px;
      }
    }
  }

  .page-description {
    p {
      max-width: 600px;
      margin: 4rem auto;
      text-align: center;
    }
  }

    /* Slider */

  .carousel__slide {
    padding: 0;
    display: inline-block;
  }

  .carousel__slider-tray {
    gap: 1rem;
  //   -webkit-transition: .25s ease-out;
  // -moz-transition:  .25s ease-out;
  // -o-transition:  .25s ease-out;
  // transition:  .25s ease-out;
  }

  .carousel__inner-slide {
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0;
  }

  .carousel__back-button,
  .carousel__next-button {
    height: 44px;
    width: 44px;
    padding: 0;
    background: var(--input);
    border: 1.5px solid var(--border);
    box-shadow: var(--boxShadow);
    border-radius: 23px;
    font-size: 2rem;
    line-height: 1rem;
    text-align: center;
    margin:  1rem .25rem;
    opacity: 0;
    animation: fadein 2s forwards;
    &:hover {

    }
    & > * {
      vertical-align: middle;
    }
  }
  .carousel__dot-group {
    width: auto;
    max-width: 150px;
    margin: 1rem auto;
    display: flex;
    border-bottom: none !important;
  }
  .carousel__dot {
    background: var(--greyMidLight);
    border-color: var(--greyMidLight);
    box-shadow: none;
    padding: 0;
    margin: 0 auto;
    height: 10px;
    width: 10px;
    .carousel__dot--selected,
    &:hover {
      background: var(--borderColor);
      border-color: var(--borderColor);
    }
  }
  .carousel__dot--selected {
      background: var(--borderColor);
      border-color: var(--borderColor);
    }
  .carousel-image {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.2));
    border-radius: 8px;
  }
  .carousel-content {
    display: grid;
    align-items: center;
  }
  .peek {
    padding: 0 2rem;
    .carousel__inner-slide {
      margin-left: -1rem;
      margin-right: -1rem;
    }
  }
  .product-gallery {
    margin: 3rem 0;
  }
  .card-slide {
    padding: 2rem;
    @media (max-width: 1023px) {
      padding: 0;
    }
  }
  .carousel-button-group {
    display: block;
    text-align: center;
    &.right {
      text-align: right;
    }
  }
  .carousel__slide {
    h6 {
      max-width: 500px;
      margin-top: 3rem;
      text-align: left;
    }
    p {
      max-width: 500px;
      margin-top: 2rem;
      text-align: left;
    }
  }
  .carousel__slide--hidden {
    h6,p {
      visibility: hidden;
    }
  }
  .product-gallery {
    .carousel__container {
      max-width: 1000px;
      margin: auto;
    }
    .carousel__slider {
      padding-left: 25%;
      padding-right: 25%;
      @media(max-width: 1023px) {
        padding-left: 2%;
        padding-right: 2%;
      }
    }
    
    .carousel__inner-slide {
      width: calc(100% - 3rem);
      height: calc(100% - 3rem);
      left: 1.5rem;
      top: 1.5rem;
      margin: 0 auto;
      @media(max-width: 1023px) {
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
      }
    }
  }
  @media(max-width: 1023px) {
  .card-gallery {
    .carousel__container {
      max-width: 1000px;
      margin: auto;
    }
    .carousel__slider {
        padding-left: 7%;
        padding-right: 7%;
    }
    
    .carousel__inner-slide {
      left: 1.5rem;
      top: 1.5rem;
      margin: 0 auto 2rem;
      
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
    }
  }
}
  .more-products {
    overflow: hidden;
    margin-top: 3rem;
  }
  .more-products-header {
    margin-top: 3rem;
    padding: 0;
    display: flex;
    align-items: center;
    .container  {
      margin-left: auto;
    }
    @media (max-width: 1023px) {
      flex-direction: column;
      .container {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }
`;

export default GlobalStyles;
