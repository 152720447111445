// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-approach-js": () => import("./../../../src/pages/approach.js" /* webpackChunkName: "component---src-pages-approach-js" */),
  "component---src-pages-bontpilot-js": () => import("./../../../src/pages/bontpilot.js" /* webpackChunkName: "component---src-pages-bontpilot-js" */),
  "component---src-pages-dealers-js": () => import("./../../../src/pages/dealers.js" /* webpackChunkName: "component---src-pages-dealers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/Collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-discipline-js": () => import("./../../../src/templates/Discipline.js" /* webpackChunkName: "component---src-templates-discipline-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/Product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/Series.js" /* webpackChunkName: "component---src-templates-series-js" */),
  "component---src-templates-support-js": () => import("./../../../src/templates/Support.js" /* webpackChunkName: "component---src-templates-support-js" */)
}

