import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const NavInfoStyled = styled.div`
  ul {
    list-style: none;
    margin: 0 0 1rem;
    padding: 0;
  }
  li {
    padding: 1rem 0;
    text-transform: uppercase;
    text-align: right;
    padding: 0 0 0.5rem;
    a {
      color: var(--watermelon);
    }
  }
  @media (max-width: 390px) {
    h4:first-of-type {
      border-top: transparent;
    }
  }
  a.h4 {
    padding-bottom: 2rem;
  }
  a.h4:last-of-type {
    border-bottom: 1px solid var(--white);
  }
  a {
    color: var(--white);
    text-decoration: none;
    cursor: pointer;
  }
  p {
    margin: 0 0 1rem;
  }
`;

export default function NavProduct({ technology, support }) {
  const footerLinks = support.nodes[0].supportPages.filter(
    (item) => item.inFooter
  );
  return (
    <NavInfoStyled>
      <h4>{technology.nodes[0].title}</h4>
      <ul>
        {technology.nodes[0].technologyPages.map((item) => (
          <li>
            <Link to="/technology/">{item.title}</Link>
          </li>
        ))}
      </ul>
      <h4>{support.nodes[0].title}</h4>
      <ul>
        {footerLinks.map((item) => (
          <li>
            {item.href ? (
              <a href={item.href} target="_blank" rel="noreferrer">
                {item.title}
              </a>
            ) : (
              <Link to={`/support/${item.slug.current}`}>{item.title}</Link>
            )}
          </li>
        ))}
      </ul>
      <Link className="h4" to="/approach">
        Approach
      </Link>
      <Link className="h4" to="/dealers">
        Where to buy
      </Link>
    </NavInfoStyled>
  );
}
